
/**
 * example emotions: happy, neutral, thinking, angry
 * face: change anything about the face style in object notation
 *      e.g. offset transform or scale, display, transparency
 * eyes: change any property of the eyes
 *      e.g. color (background-color), size, border-radius or how much they glow
 * mouth: change properties of the mouth. I think you get it
 * classes: add a classes array to any part of the face to choose from predefined classes
 *      e.g. the red glowing eyes use the red-glow class
 */
const emotions = require("@/components/oabot/oabotEmotions.json").emotions
export default {
    name: "Oabot",
    props: {
        /**
         * time before Oabot switches to next emotion
         */
        emotionDuration: {
            type: Number,
            default: 5000,
        },
        /**
         * time between closing and opening eyes when blinking
         */
        blinkDuration: {
            type: Number,
            default: 100,
        },
        /**
         * minimum time that has to be between two consecutive blinks
         */
        blinkIntervalMin: {
            type: Number,
            default: 1000,
        },
        /**
         * maximum time that can be between two consecutive blinks
         */
        blinkIntervalMax: {
            type: Number,
            default: 5000,
        },
        /**
         * when the client mouse is moved Oabot will look around and follow it
         * this number scales how much he is looking around
         * set to 0 if you don't want oabot to follow the client mouse
         */
        faceLookScaling: {
            type: Number,
            default: 5,
        },
        /**
         * Array of String identifiers for emotions that can be shown
         */
        permittedEmotions: {
            type: Array,
            default: () => Object.keys(emotions),
            validator: (val) =>
                val.every((el) => {
                    return Object.keys(emotions).includes(el)
                }),
        },
        /*
         * Manually set the current emotion with its string key
         * to return back to Oabot's standard routine set this back to null
         */
        setEmotion: {
            type: String,
            default: null,
            validator: (val) => Object.keys(emotions).includes(val),
        },
        /**
         * prop for defining one or two custom Emotions directly
         * from where the Oabot is used.
         * will not work in this commit.
         */
        customEmotions: {
            type: Object,
            default: null,
        },
        /**
         * applies "floating" CSS animation
         */
        floating: {
            type: Boolean,
            default: false,
        },
        /**
         * allows to switch between video source highres: 2MB
         * and video source lowres: 200KB
         */
        highres: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            face: null,
            oabot: null,
            current_state: 0,
            canvas: null,
        }
    },
    computed: {
        current_emotion() {
            if (
                this.setEmotion &&
                this.permittedEmotions.includes(this.setEmotion)
            )
                return emotions[this.setEmotion]
            return emotions[this.permittedEmotions[this.current_state]]
        },
        currentEyes() {
            return this.current_emotion.eyes
        },
        currentMouth() {
            return this.current_emotion.mouth
        },
        backgroundSrc() {
            return this.highres
                ? require("@/assets/images/static/oabot_bg_high.mp4")
                : require("@/assets/images/static/oabot_bg_low.mp4")
        },
    },
    mounted() {
        this.face = this.$refs["face"]
        this.oabot = this.$refs["oabot"]
        window.addEventListener("mousemove", this.move_face)

        setTimeout(this.change_emotion, this.emotionDuration)
        setTimeout(
            this.blink,
            this.randomRange(this.blinkIntervalMin, this.blinkIntervalMax)
        )
    },
    beforeDestroy() {
        window.removeEventListener("mousemove", this.move_face)
    },
    methods: {
        randomRange(min, max) {
            return Math.random() * (max - min) + min
        },
        /**
         * resets the style applied in blink function
         */
        unblink() {
            const eyeStyleR = this.face.children[0].style
            const eyeStyleL = this.face.children[1].style
            // reset right eye
            eyeStyleR.height = this.current_emotion.eyes.right.height
            eyeStyleR.marginTop = 0
            // reset left eye
            eyeStyleL.height = this.current_emotion.eyes.left.height
            eyeStyleL.marginTop = 0
        },
        /**
         * Applies blinking style to the eyes
         * the after blinkDuration is elapsed resets to current emotion eye style
         * @param {Number} blinkDuration: defines the time eyes are closed in milliseconds
         * @param {Number} blinkIntervalMin: defines the minimum time between blinks
         * @param {Number} blinkIntervalMax: defines the maximum time between blinks
         * the blink interval is chosen randomly between [blinkIntervalMin, blinkIntervalMax].
         */
        blink() {
            if (this.current_emotion.face.noblink) {
                setTimeout(
                    this.blink,
                    this.randomRange(
                        this.blinkIntervalMin,
                        this.blinkIntervalMax
                    )
                )
                return
            }
            const eyeStyleR = this.face.children[0].style
            const eyeStyleL = this.face.children[1].style

            eyeStyleR.height = "2%"
            eyeStyleR.marginTop = "30%"
            eyeStyleL.height = "2%"
            eyeStyleL.marginTop = "30%"

            setTimeout(this.unblink, this.blinkDuration)
            setTimeout(
                this.blink,
                this.randomRange(this.blinkIntervalMin, this.blinkIntervalMax)
            )
        },
        move_face(event) {
            if (this.current_emotion.face.nomove) return
            const dX = -(
                ((this.face.offsetLeft +
                    this.face.clientWidth / 2 -
                    event.clientX) /
                    window.innerWidth) *
                this.faceLookScaling
            )
            const dY = -(
                ((this.face.offsetTop +
                    this.face.clientHeight / 2 -
                    event.clientY) /
                    window.innerHeight) *
                this.faceLookScaling
            )
            this.face.style.left = (20.0 + dX).toString() + "%"
            this.face.style.top = (20.0 + dY).toString() + "%"
        },
        /**
         * changes the current_emotion of oabot through current_state
         * either to the next emotion in line or TO the specified one.
         * @param {String} to: Name of the emotion that is being switched to. (if null it just goes to the next emotion that is upcoming)
         * @param {Number} next: time before next emotion is shown (also time that this current emotion is shown)
         */
        change_emotion(to, next) {
            if (to) {
                this.current_state =
                    this.permittedEmotions.indexOf(to) || this.current_state
                setTimeout(this.change_emotion, next || this.emotionDuration)
                return
            }
            this.current_state =
                (this.current_state + 1) % this.permittedEmotions.length
            setTimeout(this.change_emotion, next || this.emotionDuration)
        },
    },
}
