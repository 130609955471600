
import Oabot from "@/components/oabot/Oabot"
import ChatMessagesContainer from "@/components/chat/ChatMessagesContainer"
import Button from "@/components/base/Button"
import traineeNavigationMixin from "@/mixins/traineeNavigationMixin"
const SELF_ID = 0
import { OABALYTICS_INDEX_CLICK } from "@/enums/oabalytics"

export default {
    name: "OabotOnboardingTeaser",
    components: { Button, ChatMessagesContainer, Oabot },
    mixins: [traineeNavigationMixin],
    data: () => ({
        oabotEmotion: ["happy", "neutral", "thinking"],
        messages: [],
        selfId: SELF_ID,
        messageQueue: [
            {
                body: "Hi ich bin Oabot",
                sender_id: 1,
                type: "regular",
            },
            {
                body: "Wenn du noch nicht weißt, welcher Beruf der richtige für dich ist, helfe ich dir gerne bei der Suche.",
                sender_id: 1,
                type: "regular",
            },
            {
                body: "Dafür brauche ich nur ein paar Infos von dir.",
                sender_id: 1,
                type: "regular",
            },
        ],
        messageInterval: null,
        observer: null,
    }),
    mounted() {
        this.observer = new IntersectionObserver((entries) => {
            const firstEntry = entries[0]
            if (firstEntry.isIntersecting) {
                this.startMessageQueue()
                this.observer.disconnect()
            }
        })
        this.observer.observe(this.$refs["chatMessages"].$el)
    },
    destroyed() {
        clearInterval(this.messageInterval)
    },
    methods: {
        trackClick(params) {
            this.$oabalytics.trackEvent(OABALYTICS_INDEX_CLICK, params)
        },
        startMessageQueue() {
            this.messageInterval = setInterval(() => {
                this.pushNextMessage()
            }, 1000)
        },
        pushNextMessage() {
            if (this.messageQueue.length > 0) {
                const message = this.messageQueue.shift()
                this.pushMessage(message)
            } else {
                clearInterval(this.messageInterval)
            }
        },
        pushMessage(message) {
            this.messages.push({
                created_at: new Date().toISOString(),
                visible_to_sender: 1,
                can_delete: false,
                can_edit: false,
                preview: false,
                body: message.body,
                type: message.type,
                sender_id: message.sender_id ?? null,
            })
        },
        openRegistrationDialog() {
            this.trackClick("oabot_start-register")
            if (this.$auth.loggedIn) {
                this.$router.push(this.traineeHomeRoute)
            }
            this.$store.dispatch("account/prepareOnboarding", true)
            this.$store.dispatch("account/showAuth")
        },
        openLoginDialog() {
            this.trackClick("oabot_start-login")
            this.$store.dispatch("account/showAuth")
        },
    },
}
